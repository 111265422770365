// == Import NPM
import { useForm } from "react-hook-form";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useEffect, React } from "react";
// == Import Styles
import "./contactform.scss";

// == Import Local
import place from "../../assets/img/nav/place.png";
import mail from "../../assets/img/nav/mail.png";
import internet from "../../assets/img/nav/internet.png";

// == Component
function ContactForm() {
  // Google Recapatcha
  const [verfied, setVerifed] = useState(false);
  const [message, setMessage] = useState("");

  function onChange(value) {
    setVerifed(true);
  }

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: { name: "", mail: "", subject: "", message: "" },
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ name: "", mail: "", subject: "", message: "" });
    }
  }, [formState, reset]);

  const onSubmit = (data) => {
    axios
      .post("https://api-location.benjamingodey.fr/mail", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setMessage(response.data.message);
      })
      .catch((error) => {
        console.log(error.data);
      });
  };

  return (
    <>
      <div className="contact">
        <div className="contact__left">
          <p className="contact__left--title">N'hésitez pas à me contacter</p>
          <div className="contact__element">
            <img src={place} className="contact__icon" alt="logo emplacement" />
            <p>Penvenan (22)</p>
          </div>
          <div className="contact__element">
            <img src={mail} className="contact__icon" alt="logo email" />
            <p>contact@benjamingodey.fr</p>
          </div>
          <div className="contact__element">
            <img
              src={internet}
              className="contact__icon"
              alt="logo site internet"
            />
            <a href="https://www.benjamingodey.fr">www.benjamingodey.fr</a>
          </div>
        </div>
        <div className="contact__right">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="submit--successful">{message}</div>
            <div className="form__head">
              <div className="form__element form__element--name">
                <label htmlFor="name" className="form__label">
                  Votre Nom
                </label>
                <input
                  className="form__input"
                  type="text"
                  id="name"
                  {...register("name", {
                    required: "Ce champ est requis",
                    maxLength: { value: 30, message: "Maximum 30 caractères" },
                    minLength: { value: 2, message: "Entrée invalide" },
                  })}
                />
                {errors.name && <span>{errors.name.message}</span>}
              </div>
              <div className="form__element form__element--mail">
                <label htmlFor="mail" className="form__label">
                  Votre Mail
                </label>
                <input
                  className="form__input"
                  type="mail"
                  id="mail"
                  {...register("mail", {
                    required: "Ce champ est requis",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Adresse mail incorrecte",
                    },
                  })}
                />
                {errors.mail && <span>{errors.mail.message}</span>}
              </div>
            </div>
            <div className="form__element">
              <label htmlFor="subject" className="form__label">
                Sujet
              </label>
              <input
                className="form__input"
                type="text"
                id="subject"
                {...register("subject", {
                  required: "Ce champ est requis",
                  maxLength: { value: 30, message: "Maximum 30 caractères" },
                  minLength: { value: 2, message: "Entrée invalide" },
                })}
              />
              {errors.subject && <span>{errors.subject.message}</span>}
            </div>
            <div className="form__element">
              <label htmlFor="message" className="form__label">
                Votre message
              </label>
              <textarea
                className="form__input"
                type="textarea"
                id="message"
                {...register("message", {
                  required: "Ce champ est requis",
                  maxLength: { value: 400, message: "Maximum 400 caractères" },
                  minLength: { value: 2, message: "Entrée invalide" },
                })}
              />
              {errors.message && <span>{errors.message.message}</span>}
            </div>
            <ReCAPTCHA
              sitekey="6LeouOUgAAAAANIL-D3W0Z18hllJsqnRWlLHqaLR"
              onChange={onChange}
              className="recaptcha"
            />
            <button disabled={!verfied} type="submit" className="form__button">
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
