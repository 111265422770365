// == Import NPM
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

// == Import Local
import ploumanach from "../../assets/img/carousel/ploumanach.jpeg";
import plougrescant from "../../assets/img/carousel/plougrescant.jpeg";
import brehat from "../../assets/img/carousel/brehat.jpeg";
// == Import Styles
import "./carousel.scss";

// == Composant
function Carousel() {
  return (
    <AliceCarousel
      autoPlay
      autoPlayInterval="1500"
      animationType="fadeout"
      animationDuration={3000}
      disableButtonsControls="true"
      autoPlayStrategy="none"
      infinite="true"
    >
      <img src={brehat} className="sliderimg" alt="Baie de Bréhat" />
      <img src={ploumanach} className="sliderimg" alt="Ploumanac'h" />
      <img
        src={plougrescant}
        className="sliderimg"
        alt="Maison entre les rochers Plougrescant"
      />
    </AliceCarousel>
  );
}

export default Carousel;
