// == Import NPM
import { useState, useEffect } from "react";

// == Import Styles
import "./proximite.scss";

// == Import Component
import Map from "../Map";

// == Component

function Proximite() {

  const [scrollWave1, setScrollWave1] = useState(false);
  const [scrollWave2, setScrollWave2] = useState(false);
  const [scrollWave3, setScrollWave3] = useState(false);
  const [scrollWave4, setScrollWave4] = useState(false);
  const [scrollWave5, setScrollWave5] = useState(false);
  const [scrollWave6, setScrollWave6] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollWave1(window.scrollY > window.innerHeight * 0.3);
      setScrollWave2(window.scrollY > window.innerHeight * 0.7);
      setScrollWave3(window.scrollY > window.innerHeight * 1.1);
      setScrollWave4(window.scrollY > window.innerHeight * 1.5);
      setScrollWave5(window.scrollY > window.innerHeight * 1.9);
      setScrollWave6(window.scrollY > window.innerHeight * 2.3);
    });
    return () => window.removeEventListener('scroll', {setScrollWave1, setScrollWave2, setScrollWave3, setScrollWave4, setScrollWave5, setScrollWave6});
  }, []);

  return (
    <section className="proximite">
      <Map />
      <div className={scrollWave1 ? "wave wave1 wave--scroll" : "wave wave1"}>
        <h3 className="wave__title">Ploumanac'h</h3>
        <p className="wave__content">
          Elu village préféré des Français, les rochers de granit rose de
          Ploumanac'h sont connus à travers le monde.
        </p>
        <a
          className="wave__link"
          href="https://www.bretagne-cotedegranitrose.com/offres/rochers-et-landes-de-ploumanach-perros-guirec-fr-3585949/"
        >
          En découvrir plus
        </a>
      </div>
      <div className={scrollWave2 ? "wave wave2 wave--scroll" : "wave wave2"}>
        <h3 className="wave__title">Trestraou</h3>
        <p className="wave__content">
          S'étandant sur 1,4km de sable fin, la plage de Trestraou vous offrira
          de nombreuses activiés: club pour enfants, restaurants, casino,
          centre nautique..
        </p>
        <a
          className="wave__link"
          href="https://www.tourismebretagne.com/offres/plage-de-trestraou-perros-guirec-fr-1977022/"
        >
          En découvrir plus
        </a>
      </div>
      <div className={scrollWave3 ? "wave wave3 wave--scroll" : "wave wave3"}>
        <h3 className="wave__title">Lannion</h3>
        <p className="wave__content">
          Capitale du Trégor et riche d'une longue histoire, venez découvrir ses
          maisons à pans de bois et ses petites ruelles pavées.
        </p>
        <a
          className="wave__link"
          href="https://www.tourismebretagne.com/destinations/les-10-destinations/cote-de-granit-rose-baie-de-morlaix/lannion/"
        >
          En découvrir plus
        </a>
      </div>
      <div className={scrollWave4 ? "wave wave4 wave--scroll" : "wave wave4"}>
        <h3 className="wave__title">Marais de Trestel</h3>
        <p className="wave__content">
          A proximité de la plage du même nom et s'étandant sur 32 ha, ce marais
          héberge une biodiversité exceptionnelle.
        </p>
        <a
          className="wave__link"
          href="https://www.bretagne-cotedegranitrose.com/mon-sejour/visites-et-decouvertes/les-espaces-naturels/decouverte-du-marais-de-trestel-a-trevou-treguignec/"
        >
          En découvrir plus
        </a>
      </div>
      <div className={scrollWave5 ? "wave wave5 wave--scroll" : "wave wave5"}>
        <h3 className="wave__title">Presqu'île Renote</h3>
        <p className="wave__content">
          Classé "Site Remarquable", vous bénéficierez d'une vue imprenable sur
          les 7 îles et le phare de Ploumanac'h.
        </p>
        <a
          className="wave__link"
          href="https://www.bretagne-cotedegranitrose.com/offres/le-tour-de-la-presquile-renote-tregastel-fr-3003217/"
        >
          En découvrir plus
        </a>
      </div>
      <div className={scrollWave6 ? "wave wave6 wave--scroll" : "wave wave6"}>
        <h3 className="wave__title">Les falaises de Tredrez</h3>
        <p className="wave__content">
          Cet ensemble de côte rocheuse constitue l'un des plus vastes espaces
          sauvages des Côtes d'Armor.
        </p>
        <a
          className="wave__link"
          href="https://www.bretagne-cotedegranitrose.com/offres/les-falaises-de-tredrez-tredrez-locquemeau-fr-3016962/"
        >
          En découvrir plus
        </a>
      </div>
    </section>
  );
}

export default Proximite;
