// == Import NPM
import { LightgalleryItem } from "react-lightgallery";

// == Import Styles
import "./lightbox.scss";

//== Import local
import interior1 from "../../assets/img/lightbox/interior1.jpeg";
import kitchen from "../../assets/img/lightbox/kitchen.jpeg";
import bathroom from "../../assets/img/lightbox/bathroom.jpeg";
import living from "../../assets/img/lightbox/living.jpeg";
import balcony from "../../assets/img/lightbox/balcony.jpeg";

function Lightbox() {
  return (
    <div className="gallery">
      <LightgalleryItem src={interior1}>
        <img className="gallery__image" src={interior1} alt="salon" />
      </LightgalleryItem>
      <LightgalleryItem src={kitchen}>
        <img className="gallery__image" src={kitchen} alt="cuisine"/>
      </LightgalleryItem>
      <LightgalleryItem src={bathroom}>
        <img className="gallery__image" src={bathroom} alt="salle de bain"/>
      </LightgalleryItem>
      <LightgalleryItem src={living}>
        <img className="gallery__image" src={living} alt="séjour"/>
      </LightgalleryItem>
      <LightgalleryItem src={balcony}>
        <img className="gallery__image" src={balcony} alt="balcon"/>
      </LightgalleryItem>
    </div>
  );
}

export default Lightbox;
