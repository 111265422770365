// == Import NPM
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import * as leaflet from "leaflet";


// == Import Styles
import "./map.scss";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-shadow.png";

// Resolve marker not appearing
delete leaflet.Icon.Default.prototype._getIconUrl;

leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// == Component
function Map() {
  return (
    <MapContainer
      center={[48.76667, -3.56667]}
      zoom={10}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
      />
      <Marker position={[48.836136, -3.505068]}>
        <Popup>Presqu'ile Renote</Popup>
      </Marker>
      <Marker position={[48.833014, -3.480781]}>
        <Popup>Ploumanach</Popup>
      </Marker>
      <Marker position={[48.816054, -3.458659]}>
        <Popup>Trestraou</Popup>
      </Marker>
      <Marker position={[48.68941, -3.58427]}>
        <Popup>Falaises de Tredrez</Popup>
      </Marker>
      <Marker position={[48.81921932833291, -3.350420530683208]}>
        <Popup>Marais de Trestel</Popup>
      </Marker>
      <Marker position={[48.732084, -3.459144]}>
        <Popup>Lannion</Popup>
      </Marker>
    </MapContainer>
  );
}

export default Map;
