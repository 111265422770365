// == Import NPM
import { Routes, Route } from "react-router-dom";

// == Import Components
import Nav from "../Nav";
import Homepage from "../Homepage";
import Footer from "../Footer";
import Presentation from "../Presentation";
import Proximite from "../Proximite";
import Contact from "../Contact";
import Availability from "../Availability";


// == Import Styles
import "./app.scss";

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" element={<Homepage />} /> 
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/proximite" element={<Proximite />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/disponibilites" element={<Availability />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
