// == Import NPM
import { useEffect, useState } from "react";
// == Import Styles
import "./contact.scss";

// == Import Local
import linkedin from "../../assets/img/contact/linkedin.png";
import github from "../../assets/img/contact/github.png";

// == Import Component
import ContactForm from "../ContactForm";

// == Composant
function Contact() {
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsShow(true);
      }, 400);
      return () => clearTimeout(timer);
    }, []);

  return (
    <section className="contact-page">
      <h1 className="contact-page__title">Contactez-moi!</h1>
      <h2 className={
                isShow
                  ? "contact-page__subtitle contact-page__subtitle--show"
                  : "contact-page__subtitle"
              }>
        Retrouvez-moi sur l'ensemble de mes réseaux
      </h2>
      <ul className="contact-page__list">
        <li className="contact-page__element">
          <img
            src={linkedin}
            alt="logo Linkedin"
            className="contact-page__icon"
          />
          <a href="https://www.linkedin.com/in/benjamin-godey-162397162/">
            Linkedin
          </a>
        </li>
        <li className="contact-page__element">
          <img src={github} alt="logo Github" className="contact-page__icon" />
          <a href="https://github.com/BenjaminGodey">GitHub</a>
        </li>
      </ul>
      <ContactForm />
    </section>
  );
}

export default Contact;
