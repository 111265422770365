// == Import Component
import CalendarElement from "../CalendarElement";

// == Import NPM
import { useEffect, useState } from "react";

// == Import Styles
import "./availability.scss";

// == Import Local
import airbnb from "../../assets/img/availability/airbnb.png";
import leboncoin from "../../assets/img/availability/leboncoin.png";
import abritel from "../../assets/img/availability/abritel.png";

// == Component

function Availability() {
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShow(true);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="availability">
      <div className="availability__up">
        <div>
          <h1 className="availability__title">Disponibilités</h1>
        </div>
        <div className={
                isShow
                  ? "availability__right availability__right--show"
                  : "availability__right"
              }>
          <p className="availability__right--content">
            Retrouvez-moi sur l'ensemble de ces sites pour réserver votre futur
            séjour.
          </p>
          <div className="logo__container">
            <div className="logo">
              <img src={airbnb} alt="logo airbnb" className="logo__picture" />
              <a href="https//www.airbnb.fr" className="logo__link">
                AirBnb
              </a>
            </div>
            <div className="logo">
              <img
                src={leboncoin}
                alt="logo airbnb"
                className="logo__picture"
              />
              <a href="https//www.leboncoin.fr" className="logo__link">
                Le Bon Coin
              </a>
            </div>
            <div className="logo">
              <img src={abritel} alt="logo abritel" className="logo__picture" />
              <a href="https//www.abritel.fr" className="logo__link">
                Abritel
              </a>
            </div>
          </div>
        </div>
      </div>
      <CalendarElement />
    </section>
  );
}

export default Availability;
