// == Import Styles
import "./footer.scss";

// == Component

function Footer() {

  function goTotop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return <footer onClick= {goTotop} className="footer">Retour en haut</footer>;
}

export default Footer;
