// == Import NPM
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
/* import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"; */

//import "@fullcalevndar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import frLocale from "@fullcalendar/core/locales/fr";

/* import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css"; */
import axios from "axios";
// == Import Styles
import "./calendar.scss";
import { useEffect, useState } from "react";

// == Component
function CalendarElement() {
  const [calendarDatas, setCalendarDatas] = useState([]);

  useEffect(() => {
    const getCalendarDatas = async () => {
      const response = await axios(
        "https://api-location.benjamingodey.fr/calendar"
      );

      setCalendarDatas(response.data);
    };

    getCalendarDatas();
  }, []);

  return (
    <>
      <FullCalendar
        eventColor="#27596F"
        locale={frLocale}
        plugins={[dayGridPlugin]}
        headerToolbar={{
          start: "title",
          center: "",
          end: "prev,next",
        }}
        initialView="dayGridMonth"
        titleFormat={{ year: "numeric", month: "long" }}
        events={calendarDatas.calendar}
      />
    </>
  );
}

export default CalendarElement;
