// == Import NPM
import { NavLink } from "react-router-dom";
import { useState } from "react";

// == Import Local
import mail from "../../assets/img/nav/mail.png";
import close from "../../assets/img/nav/close.png";
import burger from "../../assets/img/nav/burger.png";
import place from "../../assets/img/nav/place.png";

// == Import Styles
import "./nav.scss";

// == Composant
function Nav() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navigation">
      <NavLink to="/" className="navigation__title">
        {/* <a href="/" className="navigation__title"> */}
        <div className="navigation__title--first">
          <p>Chez</p>
        </div>
        <div className="navigation__title--second">
          <p>Benjamin</p>
        </div>
      </NavLink>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <img
          src={isNavExpanded ? close : burger}
          className="hamburger__icon"
          alt="burger icone"
        />
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu--expanded" : "navigation-menu"
        }
      >
        <ul className="navigation-menu__list" onClick={() => {
          if (isNavExpanded){
            setIsNavExpanded(!isNavExpanded);
          }
          
        }}>
          <li className="navigation-menu__item">
            <NavLink
              to="/presentation"
              className={({ isActive }) =>
                isActive ? "nav__item nav__item--active" : "nav__item"
              }
            >
              Présentation
            </NavLink>
            {/* <a href="/home">Home</a> */}
          </li>
          <li className="navigation-menu__item">
            <NavLink
              to="/proximite"
              className={({ isActive }) =>
                isActive ? "nav__item nav__item--active" : "nav__item"
              }
            >
              A proximité
            </NavLink>
          </li>
          <li className="navigation-menu__item">
            <NavLink
              to="/disponibilites"
              className={({ isActive }) =>
                isActive ? "nav__item nav__item--active" : "nav__item"
              }
            >
              Disponibilités
            </NavLink>
          </li>
          <li className="navigation-menu__item">
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? "nav__item nav__item--active" : "nav__item"
              }
            >
              Contact
            </NavLink>
          </li>
          <li
            className="navigation-menu__item"
            id="navigation__contact-section"
          >
            <div className="contact-section">
              <div>
                <a href="mailto:contact@benjamingodey.fr">
                  <img
                    className="contact-section__logo"
                    src={mail}
                    alt="logo email"
                  />
                </a>
              </div>
              <div className="contact-section__place">
                <img
                  className="contact-section__logo"
                  src={place}
                  alt="logo lieu"
                />

                <p className="contact-section__text">Penvenan</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
