// == Import Styles
import "./presentation.scss";

// == Import Local
import sea from "../../assets/img/table-icons/sea.png";
import balcon from "../../assets/img/table-icons/balcon.png";
import wifi from "../../assets/img/table-icons/wifi.png";
import elevator from "../../assets/img/table-icons/elevator.png";
import car from "../../assets/img/table-icons/car.png";
import television from "../../assets/img/table-icons/television.png";
import kitchen from "../../assets/img/table-icons/kitchen.png";
import fridge from "../../assets/img/table-icons/fridge.png";
// == Import Components
import Lightbox from "../Lightbox";

// == Component
function Presentation() {
  return (
    <section className="presentation">
      <div className="presentation__up">
        <div className="presentation__left">
          <h3 className="presentation__title">Infos pratiques</h3>
          <p className="presentation__content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu
            mauris elit. Sed sit amet imperdiet sem, sit amet lacinia ligula.
            Aliquam erat volutpat. Suspendisse vehicula et justo quis tempus.
          </p>
          <p className="presentation__content">
            Donec euismod sit amet lectus et tempor. Nam molestie sit amet nulla
            non dapibus. Sed nec nunc pulvinar ligula posuere dapibus. Duis
            placerat malesuada justo, vel dapibus eros tincidunt vel. Duis ac
            quam volutpat quam viverra congue eu non magna.
          </p>
          <p className="presentation__intro">
            Praesent dignissim, enim id porta volutpat, ex sem dapibus arcu, in
            suscipit orci velit sed risus. Vivamus ut nisl enim.
          </p>
        </div>
        <div className="presentation__right">
          <table className="table">
            <thead className="table__head">
              <tr>
                <th>Ce que propose ce logement</th>
              </tr>
            </thead>
            <tbody className="table__body">
              <tr className="table__tr">
                <td colspan="1" className="table__td">
                  <img className="table__img" src={sea} alt="logo mer" />
                  <p>Vue mer</p>
                </td>
                <td colspan="1" className="table__td">
                  <img className="table__img" src={balcon} alt="logo balcon" />
                  <p>Balcon privé</p>
                </td>
              </tr>
              <tr className="table__tr">
                <td className="table__td">
                  <img className="table__img" src={wifi} alt="logo wifi" />
                  <p>Wifi</p>
                </td>
                <td className="table__td">
                  <img
                    className="table__img"
                    src={elevator}
                    alt="logo ascenseur"
                  />
                  <p>Ascenseur</p>
                </td>
              </tr>
              <tr className="table__tr">
                <td className="table__td">
                  <img className="table__img" src={car} alt="logo parking" />
                  <p>Parking gratuit</p>
                </td>
                <td className="table__td">
                  <img
                    className="table__img"
                    src={television}
                    alt="logo television"
                  />
                  <p>Télévision</p>
                </td>
              </tr>
              <tr className="table__tr">
                <td className="table__td">
                  <img
                    className="table__img"
                    src={kitchen}
                    alt="logo cuisine"
                  />
                  <p>Cuisine</p>
                </td>
                <td className="table__td">
                  <img
                    className="table__img"
                    src={fridge}
                    alt="logo refrigerateur"
                  />
                  <p>Réfrigérateur</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Lightbox />
    </section>
  );
}

export default Presentation;
