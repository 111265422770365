import { useRef, useEffect, useState } from "react";

// == Import Styles
import "./homepage.scss";

// == Import Local
import sand from "../../assets/img/homepage/sand.jpeg";
import brehat from "../../assets/img/homepage/brehat.jpeg";
import Carousel from "../Carousel";

// == Component
function Homepage() {
  function useIsInViewport(ref) {
    const [isInViewPort, setIsInViewport] = useState(false);

    useEffect(() => {
      function handleScroll() {
        const { y } = ref.current.getBoundingClientRect();
        return setIsInViewport(window.innerHeight - y > 30);
      }

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }, [ref, isInViewPort]);

    return isInViewPort;
  }

  const elementRef1 = useRef();
  const isInViewport1 = useIsInViewport(elementRef1);
  const elementRef2 = useRef();
  const isInViewport2 = useIsInViewport(elementRef2);

  return (
    <section className="homepage__section">
      <Carousel />
      <div className="homepage">
        <div className="homepage__up">
          <div className="homepage__content" >
            <h1 className="homepage__title" ref={elementRef1}>Bienvenue!</h1>
            <h2 className="homepage__subtitle">
              Envie de profiter d’un coucher de soleil d’été dans la mer en
              prenant un café sur la terrasse ?
            </h2>
            <p
              className={
                isInViewport1
                  ? "homepage__text homepage__text--left homepage__text--show"
                  : "homepage__text homepage__text--left"
              }
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu
              mauris elit. Sed sit amet imperdiet sem, sit amet lacinia ligula.
              Aliquam erat volutpat. Suspendisse vehicula et justo quis tempus.
              Donec euismod sit amet lectus et tempor. Nam molestie sit amet
              nulla non dapibus. Sed nec nunc pulvinar ligula posuere dapibus.
            </p>
          </div>
          <div className="homepage__image">
            <img src={sand} alt="sable et mer" className="homepage__picture" />
          </div>
        </div>
        <div className="homepage__down" ref={elementRef2}>
          <div className="homepage__image">
            <img
              src={brehat}
              alt="maison à Bréhat"
              className="homepage__picture"
            />
          </div>
          <div className="homepage__content">
            <h2 className="homepage__subtitle">
              Ut dapibus nibh congue dui porta, vel tempus metus sollicitudin.
              Duis ipsum enin.
            </h2>
            <p
              className={
                isInViewport2
                  ? "homepage__text homepage__text--right homepage__text--show"
                  : "homepage__text homepage__text--right"
              }
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu
              mauris elit. Sed sit amet imperdiet sem, sit amet lacinia ligula.
              Aliquam erat volutpat. Suspendisse vehicula et justo quis tempus.
              Donec euismod sit amet lectus et tempor. Nam molestie sit amet
              nulla non dapibus. Sed nec nunc pulvinar ligula posuere dapibus.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Homepage;
