// == Import NPM

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";

// == Import Styles
import "./styles/index.css";
import "./styles/reset.css";

// == Import Components
import App from "./components/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <LightgalleryProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LightgalleryProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
